<template>
  <div class="ppincode">
    <div class="ppincode__icon">
      <FontIcon icon="lock" size="24" />
    </div>
    <div class="ppincode__body">
      <div class="ppincode__body-title">{{ $t("modal.profile.pin") }}</div>
      <div class="ppincode__body-status" :class="{ red: !$auth.user?.code_required, green: $auth.user?.code_required }">
        {{ $auth.user?.code_required ? $t("modal.profile.enabled") : $t("modal.profile.disabled") }}
      </div>
    </div>
    <VButton v-if="!$auth.user?.code_required" type="button" class="ppincode__btn" size="38" @click="onClickPincode('pincode_set')">{{ $t("modal.profile.setup") }}</VButton>
    <VButton v-else type="button" class="ppincode__btn" size="38" @click="onClickPincode('pincode_unset')">{{ $t("modal.profile.change") }}</VButton>
  </div>
</template>

<script setup lang="ts">
import { $vfm } from "vue-final-modal";

const { $auth } = useNuxtApp();

function onClickPincode(name: string) {
  $vfm.show(name);
}
</script>

<style lang="scss">
.ppincode {
  border-radius: var(--b-radius);
  background: var(--bg_secondary__100);
  padding: 12px;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 8px;
    color: var(--color_secondary__100);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    &-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 4px;
    }

    &-status {
      &.red {
        color: var(--trix-cancel-red, #ff3030);
      }

      &.green {
        color: #72d370;
      }

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
  }

  &__btn {
    margin-left: auto;
  }
}

html.theme__dark {
  .ppincode__body-title {
    color: #ffffff;
  }
}
</style>
